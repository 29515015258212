import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'


import qs from 'qs'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60 * 1000 // request timeout
})

// export function post(url, params) {
//     // if (url == '/foreground/history/editHistory') {
//     //     axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
//     // } else {
//     //     axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//     // }
//     return new Promise((resolve, reject) => {
//         axios
//             .post(url, params)
//             .then(
//                 res => {
//                     resolve(res.data);
//                 },
//                 err => {
//                     reject(err.data)
//                 }
//             )
//             .catch(err => {
//                 reject(err.data)
//             })
//     })
// }

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers['token'] = sessionStorage['token']
        // }
        if (config.method == 'post') {
            config.headers['Content-Type'] = 'application/json';
            // config.transformRequest=[
            //   function (data) {
            //     // `transformRequest` 允许在向服务器发送前，修改请求数据
            //     // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
            //     // data.sex = 'man'
            //     console.log(data)
            //     debugger
            //     return JSON.parse(data)
            //     // 结合create_headers里的内容，在这里又新增一条信息sex=man
            //     // 因此network中查看的结果是：name=xiaoming&age=12&sex=man
            //   }
            // ]
        }
        return config;
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        // if (res.code !== 1 && res.code !== 0) {
        //     Message({
        //         message: res.mess || 'Error',
        //         type: 'error',
        //         duration: 5 * 1000
        //     })
        //
        //     // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        //     if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        //         // to re-login
        //         MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //             confirmButtonText: 'Re-Login',
        //             cancelButtonText: 'Cancel',
        //             type: 'warning'
        //         }).then(() => {
        //             store.dispatch('user/resetToken').then(() => {
        //                 location.reload()
        //             })
        //         })
        //     }
        //     return Promise.reject(new Error(res.message || 'Error'))
        // } else {
        return res
        // }
    },
    error => {
        console.log('err' + error) // for debug
        if (error.response.status == 401) {
            sessionStorage.clear()
            // this.$router.push('/login');
        }
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service
