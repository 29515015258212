<template>
  <el-container class="body-main">
    <el-aside class="nav" width="250px">
      <!-- <div class="top" v-show="showText">
        <h1>软基处理监测系统</h1>
      </div> -->
      <div class="topSelect" v-if="accountLogin == '华容护城垸二枯' || accountLogin == '华容护城垸' || accountLogin == '安造垸二枯' || 
      accountLogin == '安造垸'||accountLogin == '长春垸二枯'||accountLogin == '长春垸'">
        <el-select @change="changeValue" v-model="accountLogin" placeholder="请选择账号">
          <el-option v-for="item in LoginAccount" :key="item.userName" :label="item.userName" :value="item.password">
          </el-option>
        </el-select>
      </div>

      <el-menu :default-active="$route.path" class="ul-nav" active-text-color="#fff" router>
        <el-menu-item index="/index">
          <span class="icon icon-01"></span>
          <span>首页概览</span>
        </el-menu-item>
        <el-menu-item index="/previewOnline">
          <span class="icon icon-02"></span>
          <span>在线一览</span>
        </el-menu-item>
        <el-menu-item index="/history">
          <span class="icon icon-03"></span>
          <span>历史记录</span>
        </el-menu-item>
        <!-- <el-menu-item v-if="true" index="/excessive">
          <span class="icon icon-04"></span>
          <span>超标记录</span>
        </el-menu-item> -->
        <el-menu-item index="/alertInfo">
          <span class="icon icon-04"></span>
          <span>报警记录</span>
        </el-menu-item>
        <el-menu-item index="/statistical">
          <span class="icon icon-06"></span>
          <span>产量统计</span>
        </el-menu-item>
        <el-menu-item index="/Daystatistical">
          <span class="icon icon-06"></span>
          <span>每日统计</span>
        </el-menu-item>
        <!-- <el-menu-item index="/statis">
          <span class="icon icon-06"></span>
          <span>统计</span>
        </el-menu-item> -->
        <!-- sessionStorage.flagshow -->
        <el-menu-item v-show="flagshow" index="#">
          <span class="icon icon-05"></span>
          <span>现场影像</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="header" height="70px">
        <div class="hdr">
          <div class="bigScreen" @click="toLargeScreen"><img src="@/assets/images/big-screen.png" alt=""></div>
          <div class="time">{{ date|dateFilter }}</div>
          <div class="g-user">
            <div class="user">
              <div style="fontSize:1rem">当前用户:
                <el-dropdown @command="back">
                  <span class="el-dropdown-link name">
                    {{ user.name || '' }}
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <a class="dropdownWord" href="http://platform.hzcjkj.com/doc/%E6%96%BD%E5%B7%A5%E8%B4%A8%E9%87%8F%E8%BF%87%E7%A8%8B%E6%99%BA%E6%85%A7%E7%9B%91%E6%8E%A7%E5%B9%B3%E5%8F%B0%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.pdf" target="_blank">使用说明</a>
                    <el-dropdown-item @click="back()">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </el-header>
      <el-main class="main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { login } from '@/api/api'

import { http, show } from '../main'
import cookies from 'js-cookie'
export default {
  name: "Home",
  components: {},
  data() {
    return {
      accountLogin: '', //切换账号token
      LoginAccount: [],

      showText: true,
      flagshow: true,
      flag: false,
      timer: null,
      date: new Date(),
      user: {
        icon: require('@/assets/images/user.png'),
        name: 'test'
      }
    };
  },
  filters: {
    dateFilter(date) {
      const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
      const day = date.getDate() >= 10 ? date.getDate() : "0" + (date.getDate());
      const hour = date.getHours() >= 10 ? date.getHours() : "0" + (date.getHours());
      const minute = date.getMinutes() >= 10 ? date.getMinutes() : "0" + (date.getMinutes());
      const second = date.getSeconds() >= 10 ? date.getSeconds() : "0" + (date.getSeconds());
      return date.getFullYear() + '-' + month + '-' + day + '   ' + hour + ':' + minute + ':' + second;
    }
  },
  created() {
    this.accountLogin = sessionStorage['name'] //当前用户名
    if (this.accountLogin == '华容护城垸' || this.accountLogin == '华容护城垸二枯') {
      this.LoginAccount = [{
        userName: '华容护城垸',
        password: '华容护城垸,HRHCY888'
      }, {
        userName: '华容护城垸二枯',
        password: '华容护城垸二枯,666888'
      }]
    }
    if (this.accountLogin == '安造垸' || this.accountLogin == '安造垸二枯') {
      this.LoginAccount = [{
        userName: '安造垸',
        password: '安造垸,AZY111'
      }, {
        userName: '安造垸二枯',
        password: '安造垸二枯,666888'
      }]
    }
    if (this.accountLogin == '长春垸' || this.accountLogin == '长春垸二枯') {
      this.LoginAccount = [{
        userName: '长春垸',
        password: '长春垸,CCY666'
      }, {
        userName: '长春垸二枯',
        password: '长春垸二枯,666888'
      }]
    }

    if (sessionStorage['flagshow'] != undefined) {
      this.flagshow = false
    }
    if (sessionStorage['showHomeH1'] != undefined) {
      this.showText = false
    }
    this.user.name = sessionStorage['name'] //当前用户名
    if (sessionStorage.getItem('alias') !== null) {
      this.user.name = decodeURIComponent(sessionStorage['alias'])
    }
    this.timer = setInterval(() => {
      this.date = new Date();
    }, 1000);
    this.flag = show
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    changeValue() {
      // console.log(this.accountLogin.split(','));
      let LoginName = this.accountLogin.split(',')
      login(LoginName[0], LoginName[1]).then(res => {
        if (res.success == false) {
          this.$message.error('用户名或密码错误');
        } else {
          sessionStorage.clear()
          sessionStorage['accountType'] = res.ClientUser.accountType
          sessionStorage['token'] = res.token
          sessionStorage['name'] = res.ClientUser.name
        }
        location.reload()
      })
    },

    back() {
      sessionStorage.clear()
      cookies.remove('userName', { path: '/' })
      cookies.remove('passWord', { path: '/' })
      cookies.remove('isChecked', { path: '/' })
      this.$router.push('/login');
    },

    // 跳转至大屏
    toLargeScreen() {
      window.open("/datav", "_blank")
    }
  }
};
</script>
<style scoped>
.bigScreen {
  float: left;
  cursor: pointer;
  width: 30px;
  margin-top: 10px;
}
.dropdownWord {
  padding: 10px 5px;
}
.dropdownWord:hover {
  background: #d9edfc;
  color: #0b85e3;
}

.topSelect {
  margin-bottom: 35px;
  padding: 20px 10px 0;
}
</style>
