import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    // {
    //     path:'/',
    //     component:()=>import('@views/Home.vue'),
    //     meta:{title:'施工质量过程智慧监控平台'}

    // },
    {
        path: '/',
        name: '登录页',
        redirect: '/login'
    },
    {
        path: '/casLogin',
        name: 'GrtUrl',
        component: () => {
            return import('@/views/Home/getUrl')
        },
        meta: { hidden: true, title: "施工质量过程智慧监控平台" }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => {
            return import('@/views/login/login')
        },
        meta: { hidden: true, title: "施工质量过程智慧监控平台" }
    },
    {
        path: '',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/index',
                name: 'Index',
                component: () => {
                    return import('@/views/Home/index')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 首页概览" }
            },
            {
                path: '/previewOnline',
                name: 'PreviewOnline',
                component: () => {
                    return import('@/views/Home/previewOnline')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 在线一览" }
            },
            {
                path: '/history',
                name: 'History',
                component: () => {
                    return import('@/views/Home/history')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 历史记录" }
            },
            {
                path: '/excessive',
                name: 'Excessive',
                component: () => {
                    return import('@/views/Home/excessive')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 超标记录" }
            },
            {
                path: '/alertInfo',
                name: 'AlertInfo',
                component: () => {
                    return import('@/views/Home/alertInfo')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 预警信息" }
            },
            {
                path: '/statistical',
                name: 'Statistical',
                component: () => {
                    return import('@/views/Home/statistical')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 产量统计" }
            },
            {
                path: '/Daystatistical',
                name: 'Daystatistical',
                component: () => {
                    return import('@/views/Home/Daystatistical')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 每日统计" }
            },
            // Daystatistical
            {
                path: '/statis',
                name: 'Statis',
                component: () => {
                    return import('@/views/Home/statis')
                },
                meta: { hidden: true, title: "施工质量过程智慧监控平台 | 统计" }
            }
        ]
    },
    {
        path: '*',
        redirect: '/login'
    },
]

const router = new VueRouter({
    // 去掉链接中的#号
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 假如window.session 里面有token值就直接进去 如果不是就跳转到login
    if (to.path !== '/login') {
        if (window.sessionStorage.getItem('token')) {
            next()
        } else {
            if (to.path === '/casLogin') {
                next()
            } else {
                next('/login')
            }
        }
    } else {
        next()
    }
})


export default router
