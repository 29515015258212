import request from '@/utils/request'
import service from '../utils/request'
const apis = '/api'
// const apis = ''
export function login(userName, password) {
  return request({
    url: apis + '/login?userName=' + userName + "&passWord=" + password,
    method: 'post',
    params: {}
  })
}
export function editWarn(historyId, filePaths, status) {
  // http://192.168.0.140:30000/historyApi/editWarn
  // historyId filePaths remarks status sign
  return request({
    url: apis + '/editWarn?historyId=' + historyId + '&status=' + status + '&sign=cjkj@2020',
    method: 'post',
    data: filePaths
  })
}

export function editConfig(params, deviceId) {

  let url = apis + '/editConfig?deviceId=' + deviceId
  return service.post(url, params)

  // return post(, params)

}

export function queryGroups() {
  return request({
    url: apis + '/deviceGroups',
    method: 'get',
    params: {}
  })
}

// 获取历史数据列表
export function historyList(deviceType, current, deviceKey, size, pileDescribe, startTime, endTime, minDepth, minAsh, minPileTime, maxDepth, minAvgAsh, maxAvgAsh, maxAsh, maxPileTime) {
  let queryStr = ''
  pileDescribe = pileDescribe.replace(/\+/g, '%2B')
  if (deviceType == 'A_RD') {
    if (startTime != undefined && startTime != '') {
      queryStr += '&startTime=' + startTime
    }
    if (endTime != undefined && endTime != '') {
      queryStr += '&endTime=' + endTime
    }
  } else {
    if (startTime != undefined && startTime != '') {
      queryStr += '&startTime=' + startTime / 1000
    }
    if (endTime != undefined && endTime != '') {
      queryStr += '&endTime=' + endTime / 1000
    }
  }
  let par = ''
  if (minDepth != undefined && minDepth != '') {
    par += '&minDepth=' + minDepth  //最小桩长
  }
  if (maxDepth != undefined && maxDepth != '') {
    par += '&maxDepth=' + maxDepth //最大桩长
  }
  if (minAvgAsh != undefined && minAvgAsh != '') {
    par += '&minAvgAsh=' + minAvgAsh  //最小平均灰量
  }
  if (maxAvgAsh != undefined && maxAvgAsh != '') {
    par += '&maxAvgAsh=' + maxAvgAsh  //最大平均灰量
  }
  if (minAsh != undefined && minAsh != '') {
    par += '&minAsh=' + minAsh  //最小累计灰量
  }
  if (maxAsh != undefined && maxAsh != '') {
    par += '&maxAsh=' + maxAsh  //最大累计灰量
  }
  if (minPileTime != undefined && minPileTime != '') {
    par += '&minPileTime=' + minPileTime  //最小成桩时间
  }
  if (maxPileTime != undefined && maxPileTime != '') {
    par += '&maxPileTime=' + maxPileTime  //最大成桩时间
  }
  return request({
    url: apis + '/historys?deviceType=' + deviceType + '&page=' + current + '&deviceKey=' + deviceKey + '&size=' + size + '&pileDescribe=' + pileDescribe + queryStr + par,
    method: 'get',
    params: {}
  })
}

// 获取设备统计信息每日统计  deviceType
export function analysis(limt, deviceKey, begintime, endtime, deviceType) {
  let queryList = ''
  if (deviceKey != undefined && deviceKey != '') {
    queryList += '&deviceKey=' + deviceKey
  }
  if (begintime != undefined && begintime != '') {
    queryList += '&beginDate=' + begintime
  }
  if (endtime != undefined && endtime != '') {
    queryList += '&endDate=' + endtime
  }
  if (deviceType != undefined && deviceType != '') {
    queryList += '&deviceType=' + deviceType
  }
  return request({
    url: apis + '/analysis?limit=' + limt + queryList,
    method: 'get',
    params: {}
  })
}
// 总量统计
export function historyAnalysis(deviceKey, deviceType, beginDate, endDate) {
  if (beginDate === '') {
    beginDate = 0
  } else {
    beginDate = beginDate / 1000
  }
  if (endDate === '') {
    endDate = 9999999999
  } else {
    endDate = endDate / 1000
  }

  return request({
    url: apis + '/historyAnalysis?deviceKey=' + deviceKey + '&deviceType=' + deviceType + '&beginDate=' + beginDate + '&endDate=' + endDate,
    methods: 'get',
    params: {}
  })
}
// analysisMonth 月统计接口

// 获取历史数据列表
export function historyWarns(deviceType, current, deviceKey, size, pileDescribe, status, startday, endday) {
  pileDescribe = pileDescribe.replace(/\+/g, '%2B')
  let querStr = ''
  if (status != undefined && status != '') {
    querStr += '&status=' + status
  }
  if (deviceType == 'A_RD') {
    if (startday != undefined && startday != '') {
      querStr += '&startTime=' + startday
    }
    if (endday != undefined && endday != '') {
      querStr += '&endTime=' + endday
    }
  } else {
    if (startday != undefined && startday != '') {
      querStr += '&startTime=' + startday / 1000
    }
    if (endday != undefined && endday != '') {
      querStr += '&endTime=' + endday / 1000
    }
  }
  return request({
    url: apis + '/historyWarns?deviceType=' + deviceType + '&page=' + current + '&deviceKey=' + deviceKey + '&size=' + size + '&pileDescribe=' + pileDescribe + querStr,
    method: 'get',
    params: {}
  })
}


// 设备列表接口http://iot.hzcjkj.com/device/list?size=2&page=4
export function queryDevices(size, page, deviceType, name) {
  let par = ''
  if (name != undefined) {
    par += '&name=' + name
  }
  return request({
    url: apis + '/devices?size=' + size + '&current=' + page + '&deviceType=' + deviceType + par,
    method: 'get',
    params: {}
  })
}
// 获取最新实时数据
export function lastData(deviceKey, pileId) {
  return request({
    url: apis + '/lastData?deviceKey=' + deviceKey + '&pileId=' + pileId,
    method: 'get',
    params: {}
  })
}

// 获取实时数据列表
export function datas(deviceKey, pileId, fields) {
  return request({
    url: apis + '/datas?deviceKey=' + deviceKey + '&pileId=' + pileId + '&fields=' + fields,
    method: 'get',
    params: {}
  })
}
// 获取正在施工的桩号
export function pileIds(deviceKey) {
  return request({
    url: apis + '/pileIds?deviceKey=' + deviceKey,
    method: 'get',
    params: {}
  })
}

export function historySum() {
  return request({
    url: apis + '/groupSum',
    method: 'get',
    params: {}
  });
}

export function newest() {
  return request({
    url: apis + '/newest?contains=true',
    method: 'get',
    params: {}
  });
}

export function queryAlarm(size, page, deviceKey, startTime, endTime) {
  if (startTime === '') {
    startTime = 0
  } else {
    startTime = startTime / 1000
  }
  if (endTime === '') {
    endTime = 9999999999
  } else {
    endTime = endTime / 1000
  }
  page = page - 1
  return request({
    url: apis + '/alarms?size=' + size + '&page=' + page + '&deviceKey=' + deviceKey + '&startTime=' + startTime + '&endTime=' + endTime,
    method: 'get',
    params: {}
  })
}


// 加灰记录 http://localhost:9000/historyBacks?page=1&size=10&deviceKey=MP01123412341234
export function historyBacks(page, size, deviceKey) {
  return request({
    url: apis + '/historyBacks?page=' + page + '&size=' + size + '&deviceKey=' + deviceKey,
    method: "get",
    params: {}
  })
}

export function pileMap(deviceKey, beginTime, endTime) {
  return request({
    url: apis + '/pileMap?deviceKey=' + deviceKey + '&beginTime=' + beginTime + '&endTime=' + endTime,
    method: 'get',
    params: {}
  })
}

