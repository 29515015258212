import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
// 引入样式文件
import '@/assets/css/style.css'

// dialog拖拽
import elementUtils from 'vue-element-utils'
Vue.use(elementUtils);

// 引入公用js
import util from '@/assets/js/util.js'
Vue.prototype.util = util;

// 引入ElementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入swiper插件
import VueAwesomeSwiper from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default global options } */)

// 引入高德地图
import AmapVue from '@amap/amap-vue'
AmapVue.config.key = '4ccd6bb77b8700097e0a760caf04cd67'
Vue.use(AmapVue)

// 引入视频播放插件
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
// 引入天地图插件
// main.ts
import VueTianditu from "vue-tianditu";
Vue.use(VueTianditu, {
  v: "4.0", //目前只支持4.0版本
  tk: "49c170030456e7128f9a9b640a2ed07f"
});
// 引入excel表格插件
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
// 引入umy-ui
import { UTable, UTableColumn } from 'umy-ui';
Vue.use(UTable)
Vue.use(UTableColumn)


// 引入echarts
// import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size 
// import 'echarts/lib/chart/bar'
// import 'echarts/lib/component/tooltip'

// If you want to use ECharts extensions, just import the extension package and it will work
// Taking ECharts-GL as an example:
// You only need to install the package with `npm install --save echarts-gl` and import it as follows
// import 'echarts-gl'

// register component to use
// Vue.component('v-chart', ECharts)

import '@/assets/js/rem'


// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
// })
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 报表下载
let http = "https://iot.hzcjkj.com"
// 是否显示超标记录
let show = false
export { http, show }
